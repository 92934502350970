// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var typesOfFCFDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Recall from our previous lessons that the cash flow of a business is the crucial element in determining its value. The bedrock of DCF valuation is the cash flows and utilises the time value of money principle whereby every \xa31 made today is worth more than if it is made tomorrow. In this lesson, we discuss how to build a simple DCF incorporating some of the skills we have developed in the previous modules."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The first part of a DCF is to decide the forecasting period. The typical DCF involves forecasts for 5 years, or in some cases 10-15 years if the business is incredibly stable. This is because it is reasonable to make assumptions about the performance of a business in the near term. Only in a few cases would we forecast a much longer period such as in project finance, energy, or minerals."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "There are different types of cash flows which are more accurate than taking the cash change from our Cash Flow Statement. These are Free Cash Flow, Levered Free Cash Flow and Unlevered Free Cash Flow, which are more accurate because they look at the regular cash flow generation of the business."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "In almost every DCF you build, Unlevered Free Cash Flow (UFCF) will be the focus. Unlevered refers to the cash flow repayments to the additional capital providers. Recall from our previous lesson that this refers to the cash flows available to all groups in the capital structure. This ignores line items such as Net Interest Expenses, Debt repayments and one-off non-cash expenses because they are either non-recurring or relate to a specific investor group. "
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Below we will work through each formula for the different cash flows, and we will explain why each is important and it\u2019s limitations."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we begin with calculating the simple Free Cash Flow for Kellogg. This has the simplest formula. EBITDA \u2013 CAPEX. It is considered a \u2018quick and dirty\u2019 method of finding cash flow for the business. This is because EBITDA is a proxy for cash flows since it nets out the most common non-cash expense (D&A). "
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We adjust for Capex because it is the most common and typically the largest cash outflow for many businesses. Of course, it is not perfect since it does not capture significant cash movements but as a quick calculation, it can provide some use."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate Free Cash Flow for Kellogg, we first must calculate the EBITDA. This should be straight forward for us since we take the EBIT and add back the Depreciation and Amortisation charge. For Kellogg we have an EBIT of $1,752 and we add back the D&A charge of $467."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=E38+I30",
        references: [ {
            rangeSelector: {
                rowIdx: 37,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "This gives us the EBITDA of $2,219"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 6,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we subtract the Capital Expenditure (Capex) found on the Cash Flow Statement. Kellogg spent a total of $553 for 2021."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 7,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I41",
        references: [ {
            rangeSelector: {
                rowIdx: 40,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can net out the Capex and this gives us a Free Cash Flow of $1,666. "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(E7:E8)",
        references: [ {
            rangeSelector: {
                rowIdx: 6,
                colIdx: 4,
                rowCount: 2,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we will calculate Unlevered Free Cash Flows. We start with Net Operating Profit After Tax (NOPAT). We do this to realise all the tax-savings from our deductibles which save the cash. We can then add back the non-cash expenses (primarily D&A) as done in the previous cash flow from operations calculations. We also treat Capex in the same fashion."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The other major adjustment is changes in working capital. Recall from previous modules that when net working capital increases there is a decrease in cash and vice versa. That change in cash is factored into this formula. We do this to better estimate the change in cash flows."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "First, we calculate NOPAT. To do so we take Operating Profit and multiple by (1-Tax Rate) which in our example is 20%. Given Kellogg has an Operating Income of $1,752 this gives a NOPAT of $1,752 * (1 \u2013 20%) = $1,402."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 12,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=E38*(1-E12)",
        references: [ {
            rangeSelector: {
                rowIdx: 11,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 37,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we add back our non-cash expenses. Remember from the previous modules that the key non-cash expenses for Kellogg is D&A. Therefore, we have a non-cash add back of $467. "
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 13,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I30",
        references: [ {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Remember, to never add back share-based compensation because it is not a real non-cash expense and relates specifically to common shareholders therefore it is excluded. Interviewers like to trip you up on this!"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To adjust for the net working capital items, we can total the increases in the working capital liabilities and net out the increases in working capital assets. Since this is the same process from when we built our Cash Flow Statement, we can take the values from the working capital adjustments made here. In practice you may have to recalculate these from the Balance Sheet. Our Net Working Capital has decreased by 63 therefore we recognise an increase in Free Cash Flow of $63."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 14,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=(-1)*SUM(I33:I34)+SUM(I35:I36)",
        references: [ {
            rangeSelector: {
                rowIdx: 32,
                colIdx: 8,
                rowCount: 2,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 34,
                colIdx: 8,
                rowCount: 2,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we make the adjustment for Capex much like in our first example. Therefore, we net out the $553."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 15,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value,
        formula: "=I41",
        references: [ {
            rangeSelector: {
                rowIdx: 40,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can use the SUM function of the NOPAT and the various changes to give us our total UFCF."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(E13:E16)",
        references: [ {
            rangeSelector: {
                rowIdx: 12,
                colIdx: 4,
                rowCount: 4,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Summing these together give us an Unlevered Free Cash Flow of $1,379. First, this number is positive indicating the business is UFCF positive which is a good sign."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Additionally, when comparing to the Free Cash Flow calculated in the first part of the lesson, we see there is not a huge disparity. This proves that the \u2018quick and dirty\u2019 method does not give a poor estimate of cash flows. However, we can see how the adjustments we make in Unlevered Free Cash Flow make the figure we attain more accurate."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 8,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 16,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The final calculation is Levered Free Cash Flow. As you will see this is not completely different from its Unlevered counterpart, but the meanings are quite different."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Looking at our Income Statement, you can see the flow of claims. Now from Revenue to NOPAT (EBIT less our tax), the value here accrues to all the capital providers in the capital structure (equity, debt and preferred equity)."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 46,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "However, once we repay the interest calculation we have \u201cstripped\u201d out the claims of the bondholders for the period and what remains (profit after tax) is what is left for the equity and preferred equity to claim."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 39,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Again, if we make a preferred equity dividend (recall this is not dissimilar to an interest payment) then they are also \u201cstripped\u201d out of the remaining value to be claimed. In this example, there is no payment to preferred equity holders."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Therefore, when we get to Net Income the value here only accrues to the common equity holders.  This also applies to the Unlevered and Levered Free Cash Flows where, Unlevered accrues to all, hence we do not \u201cstrip\u201d out interest, and levered accrues to only common equity, which as we will show now involves adjusting for debt payments."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 48,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate the Levered Free Cash Flow, we begin with Net Income instead of NOPAT because it accounts for the interest we have paid, as explained above. Then the same adjustments are made. We also make an additional adjustment for Net Debt Borrowings."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Again, we begin with Net Income because it includes the interest payment we made and the tax savings this generates. You can see why we recalculate the taxes in the Unlevered calculation and not here. Our Net Income is $1,495 and already reflects the tax expense."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 19,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=E49",
        references: [ {
            rangeSelector: {
                rowIdx: 48,
                colIdx: 4,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We follow with the identical adjustments as in the previous example, treating D&A, other non-cash adjustments, change in net working capital and capex in the same way."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 20,
        colIdx: 4,
        formula: "=I30",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 21,
        colIdx: 4,
        formula: "=(-1)*SUM(I33:I34)+SUM(I35:I36)",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertFormula({
        rowIdx: 22,
        colIdx: 4,
        formula: "=I41",
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 20,
            colIdx: 4,
            rowCount: 3,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our final step is to make the adjustment for the Net Debt Borrowings. In this case, Kellogg has not issued (raised) any new debt but has made a repayment of $399. Therefore, the Net Debt Borrowings is negative $399 reducing our Levered Free Cash Flows."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 23,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=I46",
        references: [ {
            rangeSelector: {
                rowIdx: 45,
                colIdx: 8,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can use our SUM formula of Net Income and the adjustments to give us a Levered Free Cash Flow of $1,073."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 24,
            colIdx: 4,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=SUM(E20:E24)",
        references: [ {
            rangeSelector: {
                rowIdx: 19,
                colIdx: 4,
                rowCount: 5,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "As you can see this is a similar figure to our others but is lower due to the debt repayments. This makes intuitive sense because UFCF looks at the FCF available to all members of the capital structure whereas Levered looks at those who are the Equity investors. The main difference is the inclusion or exclusion of interest payments and debt repayments."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To reiterate, the crucial difference between Unlevered and Levered Free Cash Flows. Unlevered looks at the cash flow available to all investors in the capital structure. Hence if we sum all the Unlevered Free Cash Flows in our projections, we get an Enterprise Value for the business. Alternatively, when we sum the Levered Free Cash Flows, we get an Equity Value for the business."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To recap, we have now examined how to calculate Unlevered Free Cash Flows and its counterparts. The main difference between Unlevered and Levered is the treatment of Debt and Interest payments (included in Levered)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "For the remainder of the module, we will focus on Unlevered Free Cash Flow and any reference to cash flows or Free Cash Flows will refer to UFCF. Investment banks focus on UFCF because when valuing a business, we look to calculate enterprise value (the value of the business to all members of the capital structure). When we sum UFCF we get the total value available to all members of the capital structure which means it gives us an enterprise value for the company."
    })) ] ];
})();
export {
    typesOfFCFDescriptions
};
