// Generated by purs version 0.15.13
import * as BankingReady_Data_ExcelEditorCommand from "../BankingReady.Data.ExcelEditorCommand/index.js";
import * as BankingReady_Utility_Excel_Border from "../BankingReady.Utility.Excel.Border/index.js";
import * as BankingReady_Utility_Excel_Cell from "../BankingReady.Utility.Excel.Cell/index.js";
var discountingAndTerminalValuesDescriptions = /* #__PURE__ */ (function () {
    return [ [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Once we have projected a company\u2019s Unlevered Free Cash Flows and have calculated the WACC we can discount and sum the cash flows to find its Present Value."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertItalicLine({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 2,
            rowCount: 1,
            columnCount: 14
        },
        text: "Period"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 29,
        colIdx: 4,
        text: "#"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 30,
        colIdx: 2,
        text: "Discount Factor"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 30,
        colIdx: 4,
        text: "x"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 2,
            rowCount: 1,
            columnCount: 14
        },
        text: "Discounted Free Cash Flow"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 31,
        colIdx: 4,
        text: "$mn"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoldLine({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        text: "WACC"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 33,
        colIdx: 5
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        text: "Present Value of Cash Flows"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 2,
            rowCount: 3,
            columnCount: 14
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The following steps can be done in one, but we have broken it out to show the underlying process more clearly. First, we include a row to indicate which period we are in. At the time of writing this, there is not yet 2022 data for Kellogg's financial results so we will include 2022 in our projections. Of course, this implies we are valuing the business as of the end of 2021 which is just a technicality for this exercise."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we need to include a line labelled to count the period we are in so we can discount appropriately and efficiently."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 2,
            rowCount: 1,
            columnCount: 14
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Since we are valuing this company as of 2021, this is our start point and period 0, which we input."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 29,
        colIdx: 5,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.NoDecimalsNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we use a simple formula that links to this cell and adds 1 for each period, like a counter for the number of years that have passed."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=F30+1",
        references: [ {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So in 2022, which is 1 year ahead of our valuation date, we have a counter of 1."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can use CTRL + R to copy this formula across for our forecast period which adds to the counter for each year."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 6,
            rowCount: 1,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 6,
            rowCount: 1,
            columnCount: 10
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Our final period which is 10 years later has a period count of 10."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 15,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now recall that the Present Value of a Cash Flow is given by the following formula Present Value = Cash Flow/((1 + Discount Rate)^Number of Periods), where in this example we are setting our discount rate or WACC to 10%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 33,
            colIdx: 2,
            rowCount: 1,
            columnCount: 3
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 33,
        colIdx: 5,
        number: 0.1,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "So, in our next step, we calculate the Denominator of the equation. The Denominator is referred to as the Discount Factor. In 2022 this would be 1/((1+10%)^1) = 0.91."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 30,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value,
        formula: "=1/((1+$F$34)^G30)",
        references: [ {
            rangeSelector: {
                rowIdx: 29,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 33,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we can implement this into the Present Value formula to work out the Present Value of 2022\u2019s Cash Flows. It will require multiplying the Free Cash Flows by the Discount Factor we just calculated. For 2022, this gives us $1,402 * 0.91 = $1,274. Now this number is smaller because it reflects the opportunity cost to the investors over that year. So, the $1,402 in 2022 would be worth $1,274 in 2021."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 31,
            colIdx: 6,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=G28*G31",
        references: [ {
            rangeSelector: {
                rowIdx: 27,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 30,
                colIdx: 6,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we can use the trick we learned before to copy this process for the remaining periods because we have linked them to the correct cells. If we use CTRL + R we can copy these 3 formulae across."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 6,
            rowCount: 3,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.CopyRightOnClean({
        rangeSelector: {
            rowIdx: 29,
            colIdx: 6,
            rowCount: 3,
            columnCount: 10
        }
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "What you will notice is the discount factor that we multiply by becomes smaller, effectively reducing the value of our Free Cash Flow by a greater amount in the later periods. This makes sense because the investor's opportunity cost over this period is much higher since they would not receive these cash flows in as timely a manner."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 30,
            colIdx: 6,
            rowCount: 1,
            columnCount: 10
        },
        rangeType: BankingReady_Utility_Excel_Border.Bottom.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have calculated and discounted the cash flows for the projected period we can sum them. Summing these cash flows gives us a total PV of $9,646.43."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 35,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(G32:P32)",
        references: [ {
            rangeSelector: {
                rowIdx: 31,
                colIdx: 6,
                rowCount: 1,
                columnCount: 10
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now the next question to ask yourself is what happens after 2031 or the projection period?"
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The company could continue operating beyond this date, but we cannot forecast for unlimited periods as this would be cumbersome and non-prudent. Therefore, we use what is known as a Terminal Value to encapsulate its value beyond the projection, the Terminal Period. At this point, the company's Discount Rate and Growth Rate will remain unchanged."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 37,
        colIdx: 2,
        text: "Terminal Growth Rate"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertInputCell({
        rowIdx: 37,
        colIdx: 5
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 38,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        text: "Terminal Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 37,
            colIdx: 2,
            rowCount: 2,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Normal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "To calculate we use the Perpetuity (or Gordon) Growth Method which assumes a low growth rate forever. This utilises the formula for Perpetuity. We can find the Present Value of a Perpetuity by Cash Flow * (1 + Perp. Growth Rate)/(Discount Rate \u2013 Perp. Growth Rate)."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "The Terminal Growth Rate is often a low growth figure tied to the long-term economic growth of a country (GDP growth rate) or in line with long-term inflation averages. For our example, we use 2%."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 37,
        colIdx: 5,
        number: 2.0e-2,
        numberFormat: BankingReady_Utility_Excel_Cell.PercentageOneDecimal.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Applying this to our case we take our final period cash flows and use them in the following formula. $1,750*(1 + 2%)/(10% - 2%) = $22,319."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 38,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=P28*(1+F38)/(F34-F38)",
        references: [ {
            rangeSelector: {
                rowIdx: 27,
                colIdx: 15,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 33,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Normal.value
        }, {
            rangeSelector: {
                rowIdx: 37,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Finally, we can sum up the PV of our Free Cash Flows and our Terminal Value. Together we have the total company value."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        text: "Enterprise Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Therefore adding $9,465 and $22,319 gives us an Enterprise Value of $31,784."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=F36+F39",
        references: [ {
            rangeSelector: {
                rowIdx: 35,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 38,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Of course, in Banking we are looking to purchase/sell the Equity of the business. This means we want to provide a value per share for Kellogg. Now we currently have a value for the Enterprise Value and need a value for Equity Value. Recall we can use the Enterprise Value-Equity Value bridge that we learned in Module 2."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 42,
        colIdx: 2,
        text: "(-) Long-term Debt"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 42,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 43,
        colIdx: 2,
        text: "(+) Cash and Cash Equivalents"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 44,
        colIdx: 2,
        text: "(-) Preferred Stock"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 44,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 45,
        colIdx: 2,
        text: "(-) Non-controlling Interest"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 46,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        text: "Equity Value"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertText({
        rowIdx: 48,
        colIdx: 2,
        text: "Shares Outstanding (mn)"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.SetBoldFalse({
        rowIdx: 48,
        colIdx: 2
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertTotal({
        rangeSelector: {
            rowIdx: 49,
            colIdx: 2,
            rowCount: 1,
            columnCount: 4
        },
        text: "Implied Share Price"
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 40,
            colIdx: 2,
            rowCount: 10,
            columnCount: 4
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Here is the reverse of the bridge we created in Module 2. We have reversed it because we are moving from Enterprise Value to Equity Value therefore completing the opposite calculation. This removes the claims of the other stakeholders in the capital structure. Our final calculation will be $31,784 - $6,974 + $286 - $495 = $24,601, this is our Equity Value."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 42,
        colIdx: 5,
        number: -6974.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 43,
        colIdx: 5,
        number: 286.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 44,
        colIdx: 5,
        number: 0.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 45,
        colIdx: 5,
        number: -495.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 46,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=SUM(F41:F46)",
        references: [ {
            rangeSelector: {
                rowIdx: 40,
                colIdx: 5,
                rowCount: 6,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.TopBottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We can now find this on a per-share basis by dividing by the number of shares outstanding (ensuring the units, in this case, millions are matching). Recall that there are 342 million shares outstanding so to find our implied share price we simply take $24,601 / 342 = $71.93."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertNumber({
        rowIdx: 48,
        colIdx: 5,
        number: 342.0,
        numberFormat: BankingReady_Utility_Excel_Cell.StandardNumberFormat.value
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertAndShowFormula({
        rangeSelector: {
            rowIdx: 49,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value,
        formula: "=F47/F49",
        references: [ {
            rangeSelector: {
                rowIdx: 46,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Top.value
        }, {
            rangeSelector: {
                rowIdx: 48,
                colIdx: 5,
                rowCount: 1,
                columnCount: 1
            },
            rangeType: BankingReady_Utility_Excel_Border.Bottom.value
        } ],
        numberFormat: BankingReady_Utility_Excel_Cell.TwoDecimalsNumberFormat.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Now we have completed the DCF valuation of Kellogg and found its intrinsic value to be $71.93. Of course, this doesn\u2019t necessarily mean the business is worth $72 a share given we have made lots of assumptions. Additionally, this figure along with others will only serve as a post for the valuation discussions that will take place."
    })), new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.Highlight({
        rangeSelector: {
            rowIdx: 49,
            colIdx: 5,
            rowCount: 1,
            columnCount: 1
        },
        rangeType: BankingReady_Utility_Excel_Border.Top.value
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Bringing it all together it is crucial to remember the steps to building a DCF. You are unlikely to be tested on how to build one in Excel in your interviews at this stage, but we believe demonstration is the best form to understand how the DCF is constructed."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "We start with making the assumptions about the business; its revenue and its costs and project these financials forward for 5, 10 or 20 years. We then calculate our NOPAT. After we can make non-cash addbacks and adjust for Balance Sheet and Capex changes to get to Unlevered Free Cash Flow."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Next, we calculate our WACC and use this to discount our Cash Flows to the Present. We calculate our Terminal Value using the Perpetuity Growth Method and sum this with our Cash Flows to get the Enterprise Value. We can then calculate the Equity Value and the Implied Share Price as before."
    })) ], [ new BankingReady_Data_ExcelEditorCommand.General(new BankingReady_Data_ExcelEditorCommand.InsertBoxText({
        text: "Being able to recall these steps is crucial in preparation for your interviews so if you do not understand any at this point, you can go back and relearn until you have it perfected!"
    })) ] ];
})();
export {
    discountingAndTerminalValuesDescriptions
};
